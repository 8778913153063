@use 'sass:math';
@import 'src/styles/variables';
.error {
	padding: $padding;
	color: $red;
}

.line {
	display: flex;
	flex-wrap: wrap;
	padding: math.div($padding, 2);

	&:first-child {
		border-bottom: 1px solid $line;
	}
}

.nom {
	margin-right: auto;

	@include media('<tablet') {
		flex: calc(100% - 50px);
	}
}

.livraison {
	margin-right: auto;
}

.statutCommande {
	align-self: center;
	margin: 0 $padding;

	@include media('<tablet') {
		flex: 100%;
		order: 1;
		margin: 10px math.div($padding, 2);
	}
}

.recurrence {
	@include media('<tablet') {
		padding: math.div($padding, 2);
	}
}

.skeleton {
	width: 250px;
	height: 30px;
	margin-top: 10px;
	margin-bottom: 10px;
	background-color: $ultraLightGrey;
	@include pulse;
}
