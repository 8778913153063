@use 'sass:math';
@import 'src/styles/variables';
.th {
	vertical-align: middle;
	padding: $padding math.div($padding, 2);
	color: $grey;
	font-size: 12px;
	font-weight: 600;
	line-height: 1;
	user-select: none;
	background-color: $ultraLightGrey;
	transition: $duration color;

	&:first-child {
		padding-left: $padding;
	}

	&:last-child {
		padding-right: $padding;
	}

	&.centered {
		text-align: center;
	}
}

.checkbox {
	margin: -20px;
	padding: 20px;
}
