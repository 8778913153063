@use 'sass:math';
@import 'src/styles/variables';
.wrapper {
	padding: $padding;
	border-top: 1px solid $line;
	border-bottom: 1px solid $line;
	background-color: white;

	& + & {
		border-top: none;
	}
}

.header {
	display: flex;
	align-items: center;
	margin-bottom: 10px;
	font-weight: 600;
	line-height: 1;
	text-transform: uppercase;
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}
}

.checkbox {
	margin-right: $padding;
}

.row {
	display: flex;
	overflow: auto;

	&::-webkit-scrollbar {
		display: none;
	}
}

.key {
	flex: 0 0 50%;
	padding: math.div($padding, 2) math.div($padding, 2) math.div($padding, 2) 0;
	color: $grey;
	font-size: 12px;
	font-weight: 600;
}

.value {
	padding: math.div($padding, 2) 0;
}
