@use 'sass:math';
@import 'src/styles/variables';
.wrapper {
	vertical-align: middle;
	padding: $padding math.div($padding, 2);

	&:first-child {
		padding-left: $padding;
	}

	&:last-child {
		padding-right: $padding;
	}
}

.centered {
	text-align: center;
	width: 1px;
}

.noPointer {
	cursor: default;
}
