@use 'sass:math';
@import 'src/styles/variables';
$btnDeleteSize: 20px + $padding * 2;

.wrapper {
	position: relative;
	margin-bottom: $padding;
	border: 1px solid $line;
	border-radius: $radius;
	overflow: hidden;
}

// Bouton de suppression
.delete {
	position: absolute;
	top: 0;
	right: 0;
	width: $btnDeleteSize;
	height: $btnDeleteSize;
}

// Description du produit
.produitData {
	display: flex;
	padding: math.div($padding, 2) $btnDeleteSize math.div($padding, 2) math.div($padding, 2);
}

.img {
	flex: 0 0 30%;
	height: 65px;
	margin-right: $padding;
}

.nom {
	font-size: 12px;
	font-weight: 600;
	text-transform: uppercase;
}

.prix {
	display: block;
	color: $primary;
}

// Messages de warning (nouveau prix, disabled, interdit)
.warning {
	margin-bottom: 10px;
	padding: 0 math.div($padding, 2);
	color: $red;
	font-size: 12px;
	text-align: center;
}

// Commentaire & quantité
.wrapperButtons {
	display: flex;
	margin-bottom: 10px;
	padding: 0 $padding;
}

.plusMoins {
	margin-left: auto;
}

// Total
.total {
	display: flex;
	justify-content: space-between;
	align-items: center;
	padding: 10px $padding;

	font-size: 12px;
	line-height: 1;

	background: $line;
}

.prixTotal {
	font-size: 18px;
}
