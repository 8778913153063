@use 'sass:math';
@import 'src/styles/variables';
.wrapper {
	display: inline-flex;
	position: relative;
	align-items: center;
}

.label {
	position: relative;
	top: 1px;
	padding: math.div($padding, 2);
	line-height: 1;
	padding-right: 5px;
	user-select: none;
	white-space: nowrap;

	.readOnly & {
		cursor: default;
	}
}

.input {
	position: relative;
	top: 2px;
	width: 9ch;
	margin-right: 5px;
	text-align: center;
	border: 1px solid transparent;

	.wrapper:not(.readOnly) & {
		border-bottom-color: $line;
		border-bottom-style: dashed;
	}
}

.needed {
	color: $red;
}

.clearButton {
	position: relative;
	width: 15px;
	height: 15px;
	margin-left: -5px;
	margin-right: 10px;

	&::before,
	&::after {
		content: '';
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		width: 1px;
		margin: auto;
		transform: rotate(45deg);
		background-color: $grey;
	}

	&::after {
		transform: rotate(-45deg);
	}
}
