@use 'sass:math';
@import 'src/styles/variables';
.wrapper {
	display: flex;
	flex-wrap: wrap;
	position: relative;
	height: 100%;
	padding: $padding;
	padding-bottom: math.div($padding, 2);
	border-radius: $radius;
	background-color: #fff;
	box-shadow: $shadow;
	overflow: hidden;
	transition: $duration box-shadow;
}

.inPanier {
	box-shadow: $shadow, 0 0 0 1px $primary;
}

.btnFavori {
	position: absolute;
	top: 0;
	left: 0;
}

.left {
	margin-right: $padding;
}

@value photoWidth: 120px;
.photo {
	width: photoWidth;
	height: 110px;
}

.right {
	flex: 100px;
}

.nom {
	font-weight: 600;
	word-break: break-word;
	text-transform: uppercase;
}

.prix {
	display: block;
	color: $primary;
}

.bottom {
	display: flex;
	align-items: center;
	flex: 100%;
}

.code {
	flex: 1;
	max-width: photoWidth;
	margin-right: auto;
	color: $grey;
	font-size: 12px;
	text-align: center;
}

.code2 {
	flex: 1;
	max-width: photoWidth;
	color: $grey;
	font-size: 12px;
	margin-left: -24px;
	margin-right: auto;
	text-align: left;
	line-height: 1.3em;
}

.plusMoins {
	margin-left: 10px;
}
